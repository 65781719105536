<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <!-- Table Top -->
    <div
      class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center align-items-start mb-sm-1"
    >
      <b-button
        v-if="disableCreateBtn === false"
        variant="primary"
        class="btn btn-primary justify-content-start"
        :disabled="disableAddBtn"
        @click="onAdd"
      >
        Create
      </b-button>
      <div v-else />
      <div class="custom-search d-block d-sm-flex justify-content-end">
        <slot name="customer-header" />
        <b-form-group>
          <div class="d-flex align-items-center">
            <!-- <label class="mr-1 d-sm-inline d-none">Search</label> -->
            <b-form-input
              v-if="useCustomFilter"
              v-model="customSearchModel"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
            <b-form-input
              v-else
              v-model="searchQuery"
              placeholder="Search"
              type="text"
              class="d-inline-block mt-1 mt-sm-0"
            />
          </div>
        </b-form-group>
        <div v-if="exportExcel" class="ml-2">
          <b-button class="btn btn-success" @click="exportFile()">
            Export Excel
          </b-button>
        </div>
        <div v-if="importExcel" class="ml-2">
          <slot name="import-excel-template">
            <b-button class="btn btn-warning" @click="importExcel()">
              Import Excel
            </b-button>
          </slot>
        </div>
      </div>
    </div>
    <b-overlay
      id="overlay-background"
      spinner-variant="primary"
      :show="isBusy"
      variant="transparent"
      opacity="1.00"
      blur="0.5em"
      rounded="sm"
    >
      <b-table
        ref="refTable"
        :items="fetch"
        :filter="searchQuery"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        filter-debounce="500"
        :busy.sync="isBusy"
        striped
        hover
        sticky-header
        style="max-height: 50vh;"
      >
        <template v-for="cell in cells" #[cell]="data">
          <slot :name="cell" :data="data">
            {{ data.value }}
          </slot>
        </template>
        <!-- <template #cell(no)="data">
          <span>{{ data.index }}</span>
        </template> -->
      </b-table>
    </b-overlay>
    <div
      class="d-flex flex-wrap justify-content-center justify-content-sm-between"
    >
      <!-- showing -->
      <div class="d-flex align-items-center mb-0 mt-1">
        <!-- <span class="text-nowrap">
          Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalBranch }} entries
        </span> -->
        <b-form-select
          v-model="perPage"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="mx-1"
        />
        <span class="text-nowrap"> of {{ totalBranch }} entries </span>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon rounded-circle ml-2"
          size="sm"
          @click="refetchData"
        >
          <feather-icon icon="RotateCcwIcon" />
        </b-button>
      </div>
      <div>
        <!-- pagination -->
        <b-pagination
          v-model="currentPage"
          align="right"
          class="mt-1 mb-0"
          :total-rows="totalBranch"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        />
      </div>
    </div>
    <slot name="footer" />
  </b-card>
</template>

<script>
// import { toRefs } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BCardBody,
  BFormGroup,
  BCardHeader,
  BFormSelect,
} from 'bootstrap-vue'
import useTable from './useTable'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    BCardBody,
    BFormGroup,
    BCardHeader,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    tableColumns: {
      type: Array,
      required: true,
    },
    nameTable: {
      type: String,
      required: true,
    },
    showActionEdit: {
      type: Boolean,
      default: true,
    },
    showActionDelete: {
      type: Boolean,
      default: true,
    },
    showActionChangePassword: {
      type: Boolean,
      default: false,
    },
    customQueryFilter: {
      type: String,
      default: null,
    },
    useCustomFilter: {
      type: Boolean,
      default: false,
    },
    disableAddBtn: {
      type: Boolean,
      default: false,
    },
    disableCreateBtn: {
      type: Boolean,
      default: false,
    },
    exportExcel: {
      type: Boolean,
      default: true,
    },
    importExcel: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: 'id',
    },
    sortDirection: {
      type: String,
      default: 'asc',
    },
    extendQuery: {
      type: String,
      default: null,
    },
    // customSearchModel: {
    //   type: String,
    //   default: null,
    // },
  },
  setup(props, context) {
    // const { tableColumns } = toRefs(props)
    // const titlePage = 'Cabang'
    const {
      fetch,
      perPage,
      currentPage,
      totalBranch,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refTable,

      refetchData,
      isBusy,
      onAdd,
      submit,
      onEdit,
      onDelete,
      onChangePassword,
      onPreview,
      customSearchModel,
      exportFile,
      importFile,
    } = useTable(props, context)

    const cells = props.tableColumns.map(element => `cell(${element.key})`)
    isSortDirDesc.value = props.sortDirection === 'desc'

    return {
      fetch,
      // tableColumns,
      perPage,
      currentPage,
      totalBranch,
      dataMeta,
      perPageOptions,
      searchQuery,
      isSortDirDesc,
      refTable,

      refetchData,

      isBusy,
      onAdd,
      submit,
      onEdit,
      onDelete,
      onChangePassword,
      onPreview,
      cells,
      customSearchModel,
      exportFile,
      importFile,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
