import { ref, watch, computed } from '@vue/composition-api'
import { read, utils, writeFileXLSX } from 'xlsx'

// import store from '@/store'

// Notification
// import { useToast } from 'vue-toastification/composition/'
import { createToastInterface } from 'vue-toastification'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { serializeErrorMessage } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
import service from './service'
import 'vue-toastification/dist/index.css'

export default function useTable(props, context) {
	// Use toast
	// const toast = useToast()
	// console.log(toast, useToast())
	// toast.success("I'm a toast!")

	const refTable = ref(null)
	const isBusy = ref(false)
	const perPage = ref(15)
	const totalBranch = ref(0)
	const currentPage = ref(1)
	const perPageOptions = ['15', '30', '50', '100', 'All']
	const searchQuery = ref('')
	const isSortDirDesc = ref(true)
	const isEdit = ref(false)
	const dataJson = ref(null)

	// custom search field and it's callback
	const customSearchModel = ref('')
	watch(customSearchModel, newVal => {
		context.emit('searchFieldOnChange', newVal)
	})

	searchQuery.value = props.useCustomFilter
		? props.customQueryFilter
		: searchQuery.value
	watch(
		() => props.customQueryFilter,
		newVal => {
			searchQuery.value = props.useCustomFilter
				? props.customQueryFilter
				: searchQuery.value
		},
	)

	const dataMeta = computed(() => {
		const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalBranch.value,
		}
	})

	const refetchData = () => {
		refTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery], () => {
		// refetchData()
	})

	const onAdd = () => {
		isEdit.value = false
		context.emit('evt-add')
	}
	const onEdit = () => {
		console.log('on edit')
		isEdit.value = true
		// context.emit('evt-edit', data.item)
	}

	const onDelete = id => {
		Vue.swal({
			title: 'Are you sure to delete it?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			confirmButtonText: 'Yes, deleted it!',
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-outline-danger ml-1',
			},
			buttonsStyling: false,
			preConfirm: () => service
				.destroy({ url: props.nameTable, id })
				.then(response => {
					if (response.status === 200) {
						Vue.swal({
							icon: 'success',
							title: 'Removed!',
							customClass: {
								confirmButton: 'btn btn-success',
							},
						})
						refetchData()
					}
				})
				.catch(error => {
					const element = Vue.component('description', {
						render: h => serializeErrorMessage(h, error),
					})
					const toast = createToastInterface({
						timeout: 6000,
					})
					toast({
						component: ToastificationContent,
						props: {
							title: `Error Delete ${props.nameTable} list`,
							icon: 'AlertTriangleIcon',
							variant: 'danger',
							description: element,
						},
					})
					refetchData()
				})
		})
	}

	const onChangePassword = id => {
		context.emit('evt-change-password', id)
	}

	const onPreview = data => {
		context.emit('evt-preview', data)
	}

	const fetch = (ctx, callback) => {
		isBusy.value = true
		const defaultQuery = `page=${ctx.currentPage}&limit=${ctx.perPage}${ctx.filter !== '' ? `&keyword=${ctx.filter}` : ''
			}&sortBy=${ctx.sortBy !== '' ? ctx.sortBy : 'id'}&direction=${ctx.sortDesc === true ? 'desc' : 'asc'
			}${props.extendQuery ? props.extendQuery : ''}`
		const customQuery = `page=${ctx.currentPage}&limit=${ctx.perPage}${ctx.filter !== '' ? `${ctx.filter}` : ''
			}&sort_by=${ctx.sortBy !== '' ? ctx.sortBy : 'id'}&direction=${ctx.sortDesc === true ? 'desc' : 'asc'
			}${props.extendQuery ? props.extendQuery : ''}`
		const query = props.useCustomFilter ? customQuery : defaultQuery
		service
			.index({ url: props.nameTable, query })
			.then(response => {
				const { data, pagination, ...a } = response.data

				callback(data)
				dataJson.value = data
				if (pagination) {
					totalBranch.value = pagination.per_page === pagination.total ? 1 : pagination.total
					currentPage.value = pagination.current_page
					perPage.value = pagination.per_page === pagination.total ? 'All' : pagination.per_page
				} else {
					totalBranch.value = a.total === 'All' ? 1 : a.total
					currentPage.value = a.current_page
					perPage.value = a.per_page
				}
				isBusy.value = false
			})
			.catch(() => {
				isBusy.value = true
				// toast({
				//   component: ToastificationContent,
				//   props: {
				//     title: `Error fetching ${props.nameTable} list`,
				//     icon: 'AlertTriangleIcon',
				//     variant: 'danger',
				//   },
				// })
			})
	}

	const submit = (form, callback) => {
		console.log(isEdit.value, 'submit')
		console.log(form)
		if (isEdit.value === true) {
			service
				.update({ url: props.nameTable, data: form })
				.then(response => {
					if (response.status === 200 || response.status === 201) {
						// toast({
						//   component: ToastificationContent,
						//   props: {
						//     title: 'Berhasil',
						//     icon: 'CheckSquareIcon',
						//     variant: 'success',
						//   },
						// })
						callback(true)
						refetchData()
					}
				})
				.catch(error => {
					const element = Vue.component('description', {
						render: h => serializeErrorMessage(h, error),
					})
					const toast = createToastInterface({
						timeout: 6000,
					})
					toast({
						component: ToastificationContent,
						props: {
							title: `Error Add ${props.nameTable} list`,
							icon: 'AlertTriangleIcon',
							variant: 'danger',
							description: element,
						},
					})
					callback(false)
					refetchData()
				})
		} else {
			service
				.store({ url: props.nameTable, data: form })
				.then(response => {
					if (response.status === 200 || response.status === 201) {
						// toast({
						//   component: ToastificationContent,
						//   props: {
						//     title: 'Berhasil',
						//     icon: 'CheckSquareIcon',
						//     variant: 'success',
						//   },
						// })
						callback(true)
						refetchData()
					}
				})
				.catch(error => {
					const element = Vue.component('description', {
						render: h => serializeErrorMessage(h, error),
					})
					const toast = createToastInterface({
						timeout: 6000,
					})
					toast({
						component: ToastificationContent,
						props: {
							title: `Error Add ${props.nameTable} list`,
							icon: 'AlertTriangleIcon',
							variant: 'danger',
							description: element,
						},
					})
					callback(false)
					refetchData()
				})
		}
	}
	const exportFile = () => {
		const ws = utils.json_to_sheet(dataJson.value)
		const wb = utils.book_new()
		utils.book_append_sheet(wb, ws, 'Data')
		const datetime = new Date()
		const year = datetime.getFullYear()
		const month = datetime.getMonth()
		const date = datetime.getDate()
		const hour = datetime.getHours()
		const minute = datetime.getMinutes()
		const second = datetime.getSeconds()
		writeFileXLSX(
			wb,
			`${props.nameTable}_${year}${month}${date}${hour}${minute}${second}.xlsx`,
		)
	}
	const importFile = () => {
		console.log('import')
	}
	return {
		fetch,
		// tableColumns,
		perPage,
		currentPage,
		totalBranch,
		dataMeta,
		perPageOptions,
		searchQuery,
		isSortDirDesc,
		refTable,
		isBusy,
		refetchData,
		onAdd,
		onEdit,
		onDelete,
		submit,
		onChangePassword,
		onPreview,
		customSearchModel,
		exportFile,
		importFile,
	}
}
