import service from '@/components/Table/service'
import { onMounted, ref } from '@vue/composition-api'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { createToastInterface } from 'vue-toastification'
import { serializeErrorMessage } from '@core/utils/utils'

export default function useOrder(orderId = null) {
	const resolveInvoiceStatusVariantAndIcon = status => {
		if (status === 'UNPAID') return { variant: 'warning', icon: 'XIcon' }
		if (status === 'Proses') return { variant: 'info', icon: 'PieChartIcon' }
		if (status === 'PAID') return { variant: 'success', icon: 'CheckCircleIcon' }
		return { variant: 'secondary', icon: 'XIcon' }
	}

	const isLoading = ref(true)

	const orderDetail = ref(null)

	const retrieveOrder = id => {
		isLoading.value = true
		service
			.show({ url: 'orders', id })
			.then(response => {
				if (response.status === 200) {
					orderDetail.value = response.data.data
					// console.log(orderDetail.value)
				}
			})
			.catch(error => {
				const element = Vue.component('error-notif', {
					render: h => serializeErrorMessage(h, error),
				})
				const toast = createToastInterface({
					timeout: 6000,
				})
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error Retrieve The Order',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
						description: element,
					},
				})
			})
			.finally(() => {
				isLoading.value = false
			})
	}

	const calcTotal = item => {
		const total = parseFloat(item.price) * parseInt(item.quantity)
		return total
	}

	const subTotal = items => {
		let total = 0
		items.forEach(item => {
			// const t =
			// 	(parseFloat(item.price) -
			// 		parseFloat(item.price) * (parseFloat(item.discount) / 100)) *
			// 	parseInt(item.quantity)
			const t = calcTotal(item)
			total += t
		})
		return total
	}

	const total = orderDetail => {
		let total = subTotal(orderDetail.items)
		let voucherValue = orderDetail.voucher_usage !== null
			? parseFloat(orderDetail.voucher_usage.value)
			: 0

		if (
			orderDetail.voucher_usage !== null
			&& orderDetail.voucher_usage.type === 'percentage'
		) {
			voucherValue = total - total * (voucherValue / 100)
		}
		total = total + parseFloat(orderDetail.shipment.shipment_price) - voucherValue
		return total
	}

	const loadingShipmentSend = ref(false)

	const showShipmentModal = ref(false)
	const shipmentSubmit = shipmentPayload => {
		const data = {
			...shipmentPayload,
			order_id: orderId,
		}
		loadingShipmentSend.value = true
		service.store({ url: 'shipment/create', data }).then(response => {
			retrieveOrder(orderId)
			showShipmentModal.value = false
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			loadingShipmentSend.value = false
		})
	}

	const trackingHIstoryData = ref(null)
	const trackingModal = ref(false)
	const getTrackingHistory = orderId => {
		loadingShipmentSend.value = true
		service.show({ url: 'shipment/tracking', id: orderId }).then(response => {
			if (response.data.success === false) {
				throw response.data.error
			} else {
				trackingHIstoryData.value = response.data
				trackingModal.value = true
			}
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			loadingShipmentSend.value = false
		})
	}

	if (orderId) {
		onMounted(() => {
			retrieveOrder(orderId)
		})
	}

	const cancelOrderModal = ref(false)
	const cancelOrderLoading = ref(false)
	const cancelOrder = payload => {
		cancelOrderLoading.value = true
		service.store({ url: 'order/cancel_order', data: payload }).then(response => {
			cancelOrderModal.value = false
			retrieveOrder(orderId)
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			cancelOrderLoading.value = false
		})
	}

	const refundModal = ref(false)
	const refundLoading = ref(false)
	const refund = payload => {
		refundLoading.value = true
		service.store({ url: 'refunds', data: payload }).then(response => {
			refundModal.value = false
			retrieveOrder(orderId)
		}).catch(error => {
			console.log(error)
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
			refundModal.value = false
		}).finally(() => {
			refundLoading.value = false
		})
	}

	const processLoading = ref(false)
	const processModal = ref(false)
	const process = payload => {
		processLoading.value = true
		service.store({ url: 'order/process', data: payload }).then(response => {
			processModal.value = false
			retrieveOrder(orderId)
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			processLoading.value = false
		})
	}

	const waybillLoading = ref(false)
	const waybillModal = ref(false)
	const updateWaybill = payload => {
		waybillLoading.value = true
		service.update({ url: 'order/update-waybill', data: payload }).then(response => {
			waybillModal.value = false
			retrieveOrder(orderId)
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Retrieve The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			waybillLoading.value = false
		})
	}

	const loadingFinish = ref(false)
	const finishOrder = (payload) => {
		loadingFinish.value = true
		service.update({ url: 'order/finish', data: payload }).then(response => {
			retrieveOrder(orderId)
		}).catch(error => {
			const element = Vue.component('error-notif', {
				render: h => serializeErrorMessage(h, error),
			})
			const toast = createToastInterface({
				timeout: 6000,
			})
			toast({
				component: ToastificationContent,
				props: {
					title: 'Error Finish The Order',
					icon: 'AlertTriangleIcon',
					variant: 'danger',
					description: element,
				},
			})
		}).finally(() => {
			loadingFinish.value = false
		})
	}

	return {
		resolveInvoiceStatusVariantAndIcon,
		retrieveOrder,
		orderDetail,
		calcTotal,
		subTotal,
		total,
		isLoading,
		shipmentSubmit,
		loadingShipmentSend,
		getTrackingHistory,
		trackingHIstoryData,
		trackingModal,
		cancelOrder,
		cancelOrderLoading,
		cancelOrderModal,
		refundLoading,
		refund,
		refundModal,
		process,
		processLoading,
		processModal,
		showShipmentModal,
		waybillLoading,
		waybillModal,
		updateWaybill,
		loadingFinish,
		finishOrder,
	}
}
