<template>
  <div>
    <b-card>
      <own-table ref="reviewTable" name-table="review" :extend-query="extendQuery" :table-columns="columns" :disable-create-btn="true"
        :use-custom-filter="true" :custom-query-filter="queryFilter" sort-by="created_at" sort-direction="desc"
        :export-excel="false" @searchFieldOnChange="searchOnChange">
        <template #cell(rate)="data">
          <!-- {{ data.data.item.review_details }} -->
          <star-rating :rating="data.data.item.review_details.rate" :increment="0.5" read-only
            :star-size="20"></star-rating>
        </template>
      </own-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BFormInput,
  BBadge,
  BDropdown,
  BDropdownItem,
  BCardTitle,
  BTooltip,
  BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  ref,
  onUnmounted,
  watch,
  watchEffect,
} from '@vue/composition-api'
import StarRating from 'vue-star-rating'
import OwnTable from '@/components/Table/Table.vue'
import vSelect from 'vue-select'
import { formatDate, title } from '@core/utils/filter'
import useOrder from '@/views/apps/order/useOrder'
// import FilterFields from '../components/filter/Filter.vue'
// import calonAgenStoreModule from './calonAgenStoreModule'

export default {
  components: {
    BCard,
    // FilterFields,
    OwnTable,
    BFormInput,
    vSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCardTitle,
    BTooltip,
    BAvatar,
    StarRating
  },
  props: {
    kdProduk: {
      type: String,
      default: null
    }
  },
  setup(props) {
    // const CALON_AGEN_STORE_MODULE = 'calon-agen'
    // if (!store.hasModule(CALON_AGEN_STORE_MODULE)) {
    // 	store.registerModule(CALON_AGEN_STORE_MODULE, calonAgenStoreModule)
    // }
    // onUnmounted(() => {
    // 	store.unregisterModule(CALON_AGEN_STORE_MODULE)
    // })
    // const caloAgenTable = ref(null)
    // const regionAPiUrl = ref('https://permohonancustomer.ingco.co.id/public/api')

    const { resolveInvoiceStatusVariantAndIcon } = useOrder()
    const columns = ref([
      {
        label: 'Customer',
        key: 'user_name',
      },
      {
        label: 'Deskripsi',
        key: 'review_details.description',
      },
      {
        label: 'Rate',
        field: 'review_details.rate',
        key: 'rate',
        sortable: true,
      },
      {
        label: 'Created At',
        key: 'created_at',
        formatter: val => formatDate(val),
      },
      // {
      //   label: 'Actions',
      //   key: 'actions',
      // },
    ])
    const queryFilter = ref('')
    const search = ref('')
    const extendQuery = ref('')
    if (props.kdProduk) {
      extendQuery.value = '&kd_produk=' + props.kdProduk
    }

    const searchOnChange = val => {
      console.log(val)
      search.value = val
      queryFilter.value = search.value
    }

    return {
      columns,
      queryFilter,
      searchOnChange,
      resolveInvoiceStatusVariantAndIcon,
      formatDate,
      extendQuery
    }
  },
}
</script>
