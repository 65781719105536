import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

export default function useInvoicesList(
	perPageParam = 10,
	customer = null,
	produk = null,
) {
	// Use toast
	const refInvoiceListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'kd_transaksi', label: '#', sortable: true },

		{ key: 'tgl_order', sortable: true },
		{ key: 'tgl_expired', sortable: true },
		{ key: 'status_pembayaran', sortable: true },
		{ key: 'metode_bayar', sortable: true },

		{ key: 'user', label: 'Customer', sortable: true },
		{ key: 'total_price', sortable: true, formatter: val => `${val}` },
		{ key: 'grand_total', sortable: true, formatter: val => `${val}` },
	]
	const perPage = ref(perPageParam)
	const totalInvoices = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('tgl_order')
	const isSortDirDesc = ref(true)
	const statusFilter = ref(null)
	const paymentFilter = ref(null)

	const dataMeta = computed(() => {
		const localItemsCount = refInvoiceListTable.value
			? refInvoiceListTable.value.localItems.length
			: 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalInvoices.value,
		}
	})

	const refetchData = () => {
		refInvoiceListTable.value.refresh()
	}

	watch([currentPage, perPage, searchQuery, statusFilter, paymentFilter], () => {
		refetchData()
	})

	const fetchInvoices = (ctx, callback) => {
		store
			.dispatch('app-invoice/fetchInvoices', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				status_pembayaran: statusFilter.value,
				metode_bayar: paymentFilter.value,
				customer,
				produk,
			})
			.then(response => {
				const { data, total } = response.data
				callback(data)
				totalInvoices.value = total
			})
			.catch(() => {})
	}

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveInvoiceStatusVariantAndIcon = status => {
		if (status === 'Unpaid') return { variant: 'warning', icon: 'XIcon' }
		if (status === 'Proses') return { variant: 'info', icon: 'PieChartIcon' }
		if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
		return { variant: 'secondary', icon: 'XIcon' }
	}

	const resolveClientAvatarVariant = status => {
		if (status === 'Unpaid') return 'primary'
		if (status === 'Paid') return 'danger'
		if (status === 'proses') return 'secondary'
		return 'primary'
	}

	return {
		fetchInvoices,
		tableColumns,
		perPage,
		currentPage,
		totalInvoices,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refInvoiceListTable,
		paymentFilter,
		statusFilter,

		resolveInvoiceStatusVariantAndIcon,
		resolveClientAvatarVariant,

		refetchData,
	}
}
