import axios from '@axios'
import crypto from 'crypto'
import { Base64 } from 'js-base64'
import CryptoJS from 'crypto-js'
import Vue from 'vue'

const vm = new Vue()

function aesEncrypt(txt) {
	const iv = crypto.randomBytes(8).toString('hex')
	const cipher = CryptoJS.AES.encrypt(
		txt,
		CryptoJS.enc.Utf8.parse('82f2ceed4c503896c8a291e560bd4325'),
		{
			iv: CryptoJS.enc.Utf8.parse(iv),
			mode: CryptoJS.mode.CBC,
		},
	)

	const ret = [cipher.toString(), iv]

	return Base64.encode(ret)
}
export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchUsers(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/api/auth/customer/list', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchUser(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/api/auth/customer/view/?id=${id}`)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		deleteUser(ctx, id) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`/api/auth/customers/${id}`)
					.then(response => {
						resolve(response)
						vm.$bvToast.toast('the Customer has been deleted', {
							title: 'Customer Deleted',
						})
					})
					.catch(error => reject(error))
			})
		},
		addUser(ctx, userData) {
			userData.password = aesEncrypt(userData.password)

			console.log('userData', userData)
			return new Promise((resolve, reject) => {
				axios
					.post('/api/auth/customers', userData)
					.then(response => {
						if (response.data.errors) {
							vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
								title: 'Failed to add customer',
							})
							reject(response)
						} else {
							vm.$bvToast.toast('New Customer has been added', {
								title: 'Customer Added',
							})
							resolve(response)
						}
					})
					.catch(error => reject(error))
			})
		},
		updateUser(ctx, { id, UserData }) {
			UserData = UserData.value.data
			if (typeof UserData.password != undefined)
				UserData.password = aesEncrypt(UserData.password)
			return new Promise((resolve, reject) => {
				axios
					.patch(`/api/auth/customers/${id}`, UserData)
					.then(response => {
						if (response.data.errors) {
							vm.$bvModal.msgBoxOk(JSON.stringify(response.data.errors), {
								title: 'Failed to update',
							})
							reject(response)
						} else {
							vm.$bvToast.toast('Update Sucessfully', { title: 'Customer Added' })
							resolve(response)
						}
					})
					.catch(error => reject(error))
			})
		},
	},
}
