import axios from '@axios'

class Service {
	index({ url, query }) {
		return axios.get(`/api/auth/${url}${query ? `?${query}` : ''}`)
	}

	store({ url, data }) {
		return axios.post(`/api/auth/${url}`, data)
	}

	show({ url, id }) {
		return axios.get(`/api/auth/${url}/${id}`)
	}

	update({ url, data }) {
		return axios.patch(`/api/auth/${url}/${data.id}`, data)
	}

	destroy({ url, id }) {
		return axios.delete(`/api/auth/${url}/${id}`)
	}
}

export default new Service()
